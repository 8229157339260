import React, { Component } from 'react';

import './TopBar.css';

class TopBar extends Component {
  render() {
    return (
      <div className="TopBar">
        <p className="title">Datamonster.me</p>
      </div>
    );
  }
}

export default TopBar;
